<template>
  <v-container>
    <v-row>
      <v-col class="mt-2" cols="12">
        <div class="font-weight-regular main--text lighten-3 text-heading-1">
          {{ $t("link_posts_subtitle") }}
        </div>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :items="getLinkPosts"
          hide-default-header
          mobile-breakpoint="0"
          :footer-props="footers"
          :loading="loading"
          :items-per-page="itemsPerPage"
          :server-items-length="getLinkPostsPagination.records_total"
          @update:options="updateTable"
        >
          <template v-slot:item="{ item, index }">
            <ListItemLinkPost
              :id="item.id"
              :date="item.post_date"
              :content="item.title"
              :link="item.url"
              :publisher="item.publisher"
              :key="index"
              :is-last="index === getLinkPosts.length - 1"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListItemLinkPost from "@/components/layout/link-posts/ListItemLinkPost";
import { mapGetters } from "vuex";

export default {
  name: "LinkPostList",
  components: { ListItemLinkPost },
  created() {
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getLinkPosts", "getLinkPostsPagination"]),
    footers() {
      return {
        itemsPerPageText: this.$t("items_per_page"),
        itemsPerPageOptions: [this.itemsPerPage],
        pagination: {
          page: 1,
          itemsPerPage: this.itemsPerPage,
          pageStart: this.getLinkPostsPagination.records_from - 1,
          pageStop: this.getLinkPostsPagination.records_to,
          pageCount: this.getLinkPostsPagination.total_pages,
          itemsLength: this.getLinkPostsPagination.records_total
        },
        showCurrentPage: true
      };
    }
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10
    };
  },
  methods: {
    updateTable(e) {
      this.tableLoading = true;
      this.getDataFromApi(e.page);
    },
    getDataFromApi(page) {
      this.loading = true;
      this.$store
        .dispatch("LINK_POST_GET", {
          params: {
            paginate: this.itemsPerPage,
            page: page,
            sort: "post_date",
            order: "desc"
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: transparent;
}

.theme--light.v-data-table >>> .v-data-footer {
  border: none !important;
}
</style>
